<template>
  <div class="profile-addresses generic-list scroll-container">
    <!-- <v-container> -->
    <CategoryTitle :category="category" />
    <v-list class="mt-5">
      <v-list-item
        v-for="address in userAddresses.addresses"
        :key="address.addressId"
      >
        <!-- :class="{ zebra: index % 2 == 0 }" -->
        <v-row no-gutters align="center">
          <v-col cols="3" sm="3">
            <strong>{{ address.addressName }}</strong>
          </v-col>
          <v-col cols="6">
            <span>
              {{ address.address1 }} {{ address.addressNumber }} -
              {{ address.city }} ({{ address.province }})</span
            >
          </v-col>
          <v-col cols="3" class="d-flex justify-end">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
                  class="main-button mr-2"
                  :to="'/profile/addresses/manage/' + address.addressId"
                >
                  <v-icon medium>$edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica indirizzo</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="secondary-icon-button"
                  @click="removeAddress(address.addressId)"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </template>
              <span>Elimina indirizzo</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>

    <v-row no-gutters class="d-flex justify-space-between footer-btn pl-0">
      <v-btn color="primary" large depressed to="/profile/addresses/manage">
        {{ $t("addresses.addAddressButton") }}
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import DeliveryService from "~/service/deliveryService";
import categoryMixins from "~/mixins/category";

export default {
  name: "ProfileAddress",
  components: { CategoryTitle },
  mixins: [categoryMixins],
  data() {
    return {
      newAddress: {
        addressId: -1,
        addressName: "",
        address1: "",
        address2: "",
        addressNumber: "",
        postalcode: "",
        city: "",
        province: ""
      },
      userAddresses: []
    };
  },

  methods: {
    async fetchAddresses() {
      this.userAddresses = await DeliveryService.getWarehousesList("home");
    },
    async removeAddress(addressId) {
      const res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        await DeliveryService.removeAddress(addressId);
        await this.fetchAddresses();
      }
    },
    selectAddress(address) {
      DeliveryService.selectAddress(address.addressId).then(function() {
        //go to home page
      });
    },
    addAddress() {
      // this.$router.push({
      //   path: "/edit-addresses/",
      //   name: "/edit-addresses/",
      //   params: {
      //     address: this.newAddress,
      //     newAddress: true
      //   }
      // });
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchAddresses();
  }
};
</script>
<style lang="scss">
.profile-addresses {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .main-button {
      height: 30px !important;
      width: 30px !important;
      .v-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
      }
    }
    .secondary-icon-button {
      height: 30px !important;
      width: 30px !important;
      .v-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
